/* Global!! */
*{
  box-sizing: border-box;
  --h1-font: 3.3rem/.92 "Till-melt", serif; /* 64px */
  --h2-font: 2.045rem/1 "Till-melt", serif; /* 45px */
  --h3-font: 700 1rem/1.272 "ABCD", san-serif; /* 22px */
  --intro-font: 1.9rem/1.2 "Till-book", san-serif; /* 35px */
  --button-font: 1.3rem/1.2 "Till-mono", serif; /* 24px */
  --footnote-font: 1rem/1.187 "ABCD", san-serif; /* 16px */
  --copy-size: 0.545rem/1.166 "ABCD", san-serif; /* 12px */

  --color-yellow: #FFFCBE;
  --color-black:#000;
  --color-red: #8F0000;
  --color-white: #fff;
  --yellow-text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.75);
  --inner-page: 67%;
}
@font-face {
  font-family: "ABCD";
  src: local("EduDiatype-Regular"),
    url("./fonts/Diatype/EduDiatype-Regular.woff") format("woff"),
    url("./fonts/Diatype/EduDiatype-Regular.woff2") format("woff2");
    font-weight: 400;
}
@font-face {
  font-family: "ABCD";
  src: local("ABCDiatype-Regular-Trial"),
    url("./fonts/Diatype/EduDiatype-RegularItalic.woff") format("woff"),
    url("./fonts/Diatype/EduDiatype-RegularItalic.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
}
@font-face {
  font-family: "ABCD";
  src: local("ABCDiatype-Bold-Trial"),
    url("./fonts/Diatype/EduDiatype-Bold.woff") format("woff"),
    url("./fonts/Diatype/EduDiatype-Bold.woff2") format("woff2");
    font-weight: 700;
}
@font-face {
  font-family: "ABCD";
  src: local("ABCDiatype-Bold-Trial"),
    url("./fonts/Diatype/EduDiatype-BoldItalic.woff") format("woff"),
    url("./fonts/Diatype/EduDiatype-BoldItalic.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
}
@font-face {
  font-family: "Till-melt";
  src: local("till-melted-webfont"),
    url("./fonts/Till/till-melted-webfont.woff") format("woff"),
    url("./fonts/Till/till-melted-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "Till-mono";
  src: local("till-mono-webfont"),
    url("./fonts/Till/till-mono-webfont.woff") format("woff"),
    url("./fonts/Till/till-mono-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "Till-book";
  src: local("till-book-webfont"),
    url("./fonts/Till/till-book-webfont.woff") format("woff"),
    url("./fonts/Till/till-book-webfont.woff2") format("woff2");
}
.App {
  background-color: black;
  font-size: 22px;
  line-height: 1.2727;
  font-family: "ABCD", sans-serif;
}
a{
  text-decoration: none;
  color: inherit;
}
h1{
  font: var(--h1-font);
  text-align: center;
  text-transform: uppercase;
}
h2{
  font: var(--h2-font);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -1px
}
h3{
  font: var(--h3-font);
}
p.intro{
  font: var(--intro-font);
  letter-spacing: -0.8px;
}
.button{
  position: relative;
  font: var(--button-font);
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}
.caption{
  font: var(--footnote-font);
}
.white{
  color: var(--color-white);
}
.red{
  color: var(--color-red);
}
.black{
  color: var(--color-black);
}
.yellow{
  color: var(--color-yellow);
}
iframe{
  margin: 0 auto 5vw;
  display: block;
}

/* Header */
.Header{
  position: absolute;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  width: 100%;
  padding:20px 2%;
  top:0;
  transition: top .3s ease-in-out;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  
}
.Header .not-show{
  display: none;
}
.Header.Up{
  position: fixed;
  top: -87px;
}
.Header.Down{
  position: fixed;
  top: 0;
}
.Header .button{
  position: relative;
  font: var(--button-font);
  text-transform: uppercase;
  color: var(--color-yellow);
  flex: 0 0 33%;
  display: flex;
  justify-content: center;
}
.Header .button span{
  border: 1px solid ;
  border-radius: 40px;
  padding:12px 6% 10px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}
.Header .button:hover span{
  filter: drop-shadow(0px 0px 10px var(--color-yellow));
}
.Header .button.no-border span{
  border: 1px solid transparent;
}
.Header .button:first-child > span { margin-right: auto; }

.Header .button:nth-of-type(3) > span { margin-left: auto;  }

.Header .button a{
  color: var(--color-yellow);
}
.Header.lore .button,
.Header.lore .button a{
  color: var(--color-red);
}
.Header.about .button,
.Header.about .button a{
  color: var(--color-black);
}

.Header.lore.Show .button,
.Header.lore.Show .button a,
.Header.about.Show .button,
.Header.about.Show .button a{
  color: var(--color-yellow);
}


/* Main Menu */
.mainMenu{
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  z-index: 9;
  overflow: hidden;
  transition: opacity .3s ease-in-out;
}
.mainMenu.Show{
  opacity: 1;
}
.mainMenu.Hide{
  opacity: 0;
  pointer-events: none;
}
.mainMenu h1,
.mainMenu h2{
  color: var(--color-yellow);
  transition: all .3s ease-in-out;
}
.mainMenu h1:hover,
.mainMenu h2:hover{
  text-shadow: 0px 0px 10px #FFFCBE;
}




/* Page */
.Page{
  position: relative;
  display: block;
  padding:211px 0 160px;
}
.Page .page_inner{
  width: 86%;
  max-width: 1237px;
  margin:0 auto;
  padding:0;
  position: relative;
  z-index: 4;
}
.Page .page_inner p{
  width:var(--inner-page);
  margin: 0 auto 30px auto;
}
.Page .page_inner a{
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  transition: text-shadow .3s ease-in-out;
}
.Page .page_inner a img{
  transition: box-shadow .3s ease-in-out;
}
.Page .page_inner a:hover{
text-shadow: 0px 0px 10px #FFFCBE;
}
.Page .page_inner a:hover img{
box-shadow: 0px 0px 20px #FFFCBE;
}


.Page .page_inner h1,
.Page .page_inner h2,
.Page .page_inner h3,
.Page .page_inner h4{
  max-width: var(--inner-page);
  margin:0 auto 1em auto;
}
.Page .page_inner h2,
.Page .page_inner h3,
.Page .page_inner h4{
  margin-top: 90px;
}
.Page .wp-block-image{
  margin: 100px auto;
  padding: 0;
}
.Page .wp-block-image img{
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}
.Page sup{
  font: var(--footnote-font);
  padding-left:4px;
}

/* Page Home */
.Page.Page_Home,
.Page.Page_Stewardship,
.Page.Page_Artifacts{
  color: var(--color-yellow);
}
.Page.Page_Lore{
  color: var(--color-red);
}
.Page.Page_Home{
  padding: 140px 0 160px;
}
.Page.Page_Home .full-length-image:nth-of-type(1) .full-image-wrapper{
  margin-top: 0;
}

/* Text with footnote Block */
.text-w-footnote{
  width: var(--inner-page);
  margin: 0 auto 60px;
  position: relative;
}
.text-w-footnote .text-wrapper p{
  width: 100%;
  margin-bottom: 40px;
}

.text-w-footnote .text-wrapper{
}

.footnote-wrapper{
  font: var(--footnote-font);
  max-width: 75%;
}
.Page .footnote-wrapper p{
  position: sticky;
  top: 10px;
  margin: 0;
  width: 100%;
}
.Page .footnote-wrapper ol,
.Page .footnote-wrapper ul{
  padding-left: 30px;
}
.Page .footnote-wrapper li{
  margin-bottom: 14px;
}


/* intro Image Blocks */
.intro_crop_images img{
  max-width: 19%;
  margin: 60px auto;
  display: block;
}

/* Full Width Image Block */
.full-image-wrapper{
  margin-bottom: 5vw;
  margin-top: 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-image img{
  max-width: 100%;
  display: block;
}
.full-image-wrapper-1 .full-image{
  flex: 0 0 100%;
}
.full-image-wrapper-2 .full-image{
  flex: 0 0 calc(50% - 10px);
}
.full-image-wrapper-3 .full-image{ 
  flex: 0 0 calc(33% - 10px);
}
.full-image-wrapper-4 .full-image{
  flex: 0 0 calc(25% - 10px);
}
.full-image-wrapper-5 .full-image{
  flex: 0 0 calc(20% - 10px);
}

/* Crop Image Block */
.crop_length_images{
  max-width: var(--inner-page);
  margin:50px auto 5vw;
}
.crop_length_images img{
  width: 100%;
}
.crop-image-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crop-image-wrapper .crop-image{
  
}
.crop-image-wrapper-1 .crop-image{
  flex: 0 0 100%;
}
.crop-image-wrapper-2 .crop-image{
  flex: 0 0 calc(50% - 10px);
}
.crop-image-wrapper-3 .crop-image{ 
  flex: 0 0 calc(33% - 10px);
}
.crop-image-wrapper-4 .crop-image{
  flex: 0 0 calc(25% - 10px);
}
.crop-image-wrapper-5 .crop-image{
  flex: 0 0 calc(20% - 10px);
}

/* blockquote block */
.wp-block-quote{
  width: 100%;
  margin: 58px auto 58px;
}
.wp-block-quote p{
 font: var(--h2-font);
 letter-spacing: 0px;
 line-height: 1.1;
 margin: 0 auto 25px auto !important;
 width: 100% !important;
 text-align: center;
 text-transform: uppercase;
}
.wp-block-quote cite{
  font: var(--footnote-font);
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: block;
  color: var(--color-yellow);
}

/* Session Preview */
.Session-Preview-Wrapper{
  margin-bottom: 120px;
  margin-top: 120px;
}
.Session-Preview{
  width: 94%;
  margin: 0 auto;
  padding:60px 67px 60px 76px;
  position: relative;
}
.Session-Preview .bg-img{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  z-index: 2;
}


.Session-Preview .top-sec,
.Session-Preview .bottom-sec{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 3;
}
.Session-Preview .top-left,
.Session-Preview .bottom-right{
  flex:0 0 calc(57%);
}
.Session-Preview .top-right,
.Session-Preview .bottom-left{
  flex:0 0 calc(35%);
}

.Session-Preview .img-wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Session-Preview .img-wrap img{
  max-width: 100%;
  width: 100%;
  filter: drop-shadow(0px 0px 50px var(--color-yellow));
}
.Session-Preview .img-wrap .intro-text{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--color-black);
  padding: 30px 8% 40px;
}
.Session-Preview .img-wrap .intro-text .start,
.Session-Preview .img-wrap .intro-text .end{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Session-Preview .preview-text{
  margin: 100px 0;
  text-align: center;
  position: relative;
  z-index: 3;
}
.Session-Preview .preview-text .intro{
  font: var(--h2-font);
  letter-spacing: -1px;
  text-transform: uppercase;
}
.Session-Preview .preview-text .caption{
  margin-top: 40px;
  font: var(--footnote-font);
}
.Session-Preview .portal{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  position: relative;
  z-index: 3;
}

.Session-Preview .portal h2{
  border: 1px solid var(--color-yellow);
  border-radius: 40px;
  padding: 17px 4%;
  transition: all .3s ease-in-out;
}
.Session-Preview .portal h2:hover{
  filter: drop-shadow(0px 0px 10px var(--color-yellow));
  box-shadow: 0px 0px 10px var(--color-yellow);
}


/* Session inter */
.Session{
  position: relative;
  display: block;
  padding:150px 0 5px;
}
.Session .session_inner{
  width: 86%;
  max-width: 1237px;
  margin:0 auto;
  padding:0;
  position: relative;
  z-index: 3;
}
.Session .session_inner p{
  width:var(--inner-page);
  margin: 0 auto 22px auto;
}

.Session .session_inner h1,
.Session .session_inner h2,
.Session .session_inner h3,
.Session .session_inner h4{
  max-width: var(--inner-page);
  margin:0 auto 1em auto;
}
.Session .session_inner .session_intro h1{
  max-width: 100%;
}
.Session .wp-block-image{
  margin: 5vw auto;
  padding: 0;
}
.Session .wp-block-image img{
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.Session .session_intro{
  width: var(--inner-page);
  aspect-ratio: 1/1;
  max-height: 687px;
  margin: 0 auto 120px auto;
  position: relative;
}
.Session .session_intro .intro-text{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid var(--color-yellow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--color-black);
  padding: 30px 8% 40px;
}
.Session .session_intro .intro-text .start,
.Session .session_intro .intro-text .end{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Session .session_intro .intro-text .start h1{
  margin:20px 0 0 0;

}
.Session .session_intro .intro-text .end p{
  margin: 0 auto;
  width: 100%;
}
.Session .altar-container{
  margin-top: 160px;
}
.Session .altar-container .artifact-intro{
  margin-bottom: 5vw;
}
.Session .altar-container .artifact-title,
.Session .altar-container .artifact-author{
  margin: 0 auto;
  color: var(--color-yellow);
}
.Session .full-length-image img,
.Session .crop_length_images img,
.Session iframe{
  filter: drop-shadow(0px 0px 50px var(--color-yellow));
}

.Session .exit{
  margin: 160px auto;
  padding: 106px 0;
  max-width: 40%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.Session .exit .exit-int{
  border: 1px solid var(--color-yellow);
  border-radius: 40px;
  display: block;
  padding: 17px 0;
  transition: all .3s ease-in-out;
}
.Session .exit .exit-int:hover{
  box-shadow: 0px 0px 10px var(--color-yellow);
  text-shadow: 0px 0px 10px var(--color-yellow);
}
.Session .exit .exit-int h2{
  margin: 0 auto;
  color: var(--color-yellow);
}

/* Footer */
.Footer{
  position: relative;
  z-index: 5;
}
.Footer .link-list{
  display: flex;
  justify-content: center;
  align-items: center;
}
.Footer .link-list a{
  display: block;
  flex:0 0 25%;
  width: 100%;
  aspect-ratio: 1 / 1;
  transition: all .3s ease-in-out;
}
.Footer .link-list a:hover{
  filter: drop-shadow(0px 0px 10px var(--color-yellow));
}
.Footer .link-list a .link{
  width: 100%;
  height: 25vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  font: var(--button-font);
  color:var(--color-black);
  text-transform: uppercase;
  padding-bottom: 20px;
  aspect-ratio: 1 / 1;
}

/* Sub-Foot */
.Footer .sub-foot{
  background-color: var(--color-black);
  color: var(--color-yellow);
}
.Footer .sub-foot .top{
  text-align: center;
  padding-top: 66px;
}
.Footer .sub-foot .top h2{
  font-family: "Till-melt", serif;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  transition: all .3s ease-in-out;
}
.Footer .sub-foot .top h2:hover{
  text-shadow: 0px 0px 10px #FFFCBE;
}
.Footer .sub-foot .top p{
  margin: 0;
  font: var(--copy-size);
  margin-top: 11px;
}
.Footer .sub-foot .bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  width: 100%;
  padding:20px 2%;
}
.Footer .sub-foot .bottom .button{
  position: relative;
  font: var(--button-font);
  text-transform: uppercase;
  color: var(--color-yellow);
  display: flex;
  justify-content: center;
  border: 1px solid ;
  border-radius: 40px;
  padding:12px 2% 10px;
}
.Footer .sub-foot .bottom .button:hover{
  filter: drop-shadow(0px 0px 10px var(--color-yellow));
  box-shadow: 0px 0px 10px var(--color-yellow);
}
.hamburger{
  display: none;
  height: 23px;
  width: 30px;
  flex: 0 0 30px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  z-index: 5;
}
.hamburger span{
  display: block;
  height: 1px;
  width: 100%;
/*  transition: all .3s ease-in-out;*/
  background-color: var(--color-yellow);
}
.Header.lore .hamburger span{
  background-color: var(--color-red);
}
.Header.about .hamburger span{
  background-color: var(--color-black);
}

.Header.lore.Show .hamburger span,
.Header.about.Show .hamburger span{
  background-color: var(--color-yellow);
}
.hamburger.Show span:nth-child(1){
  transform: rotate(45deg);
  transform-origin: calc(50% - 1px);
}
.hamburger.Show span:nth-child(2){
  display: none;
}
.hamburger.Show span:nth-child(3){
  transform: rotate(-45deg);
  transform-origin: calc(50% - 1px);
}
.mobile-bg{
  display: none;
}



@media screen and (max-width:800px){
  *{
  --h1-font: 2.7rem/.828 "Till-melt", serif; /* 64px */
  --h2-font: 1.9rem/1 "Till-melt", serif; /* 45px */
  --h3-font: 700 1rem/1.272 "ABCD", san-serif; /* 22px */
  --intro-font: 1.3rem/1.2 "Till-book", san-serif; /* 35px */
  --button-font: 1.2rem/1.2 "Till-mono", serif; /* 24px */
  --footnote-font: 1rem/1.187 "ABCD", san-serif; /* 16px */
  --copy-size: 0.545rem/1.166 "ABCD", san-serif; /* 12px */
  --inner-page: 95%;
}
.App{
  font-size: 20px;
}
.Page{
  padding-bottom: 60px;
  padding-top: 140px;
}
.Page .page_inner{
  width:90%;
}
.Page.Page_Home{
  padding:95px 0 160px;
}

  .Header{
    justify-content: center;
  }
  .Header .button{
    display: none;
  }
  .Header .button.no-border{
    display: block;
    text-align: center;
    flex:0 0 100%;
  }
  .hamburger{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    top: 19px;
  }
  .hamburger.Show{
    display: flex;
    justify-content: center;
  }
  .Page{
    background: none !important;
  }
  .mobile-bg{
    background-size: 200vh !important;
    background-attachment: scroll !important;
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 2;

  }

  .Session-Preview .top-sec, 
  .Session-Preview .bottom-sec{
    flex-direction: column;
  }
  .Session-Preview .top-left,
  .Session-Preview .bottom-right{
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 50px;
  }
  .Session-Preview .top-right,
  .Session-Preview .bottom-left{
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 50px;
  }
  .Session-Preview .top-left img,
  .Session-Preview .bottom-right img,
  .Session-Preview .top-right img,
  .Session-Preview .bottom-left img{
    width: 100%;
  }
  .Session-Preview-Wrapper{
    margin: 60px 0;
  }
  .Session-Preview .portal h2{
    margin: 0 auto;
    padding: 17px 8%;
  }
  .Session-Preview .preview-text{
    margin: 0px 0 75px;
  }
  .Session-Preview{
    padding: 60px 10px 60px 10px;
  }
  .Session-Preview .img-wrap .intro-text{
    padding:10px 8% 10px;
  }
  .Session-Preview .img-wrap .intro-text .start, .Session-Preview .img-wrap .intro-text .end p,
  .Session .session_intro .intro-text .end p{
    font-size: 1.2rem;
    line-height: 1;
  }
  .Session-Preview .portal{
    margin-top: 0px;
  }

  .Session{
    padding: 100px 0 5px;
  }
  .Session .altar-container .artifact-intro{
    margin-bottom: 50px;
  }
  iframe, .full-image-wrapper, .crop_length_images{
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .Session .session_intro{
    aspect-ratio: 1/1.6;
    background-size: 60% !important;
    margin-bottom: 80px;
  }
  .Session .session_intro .intro-text .start h1{
    font-size: 2rem;
  }
  .Session .exit{
    max-width: 80%;
  }
  .Session .altar-container{
    margin-top: 80px;
  }

  .Footer .link-list{
    flex-wrap: wrap;
  }
  .Footer .link-list a{
    flex:0 0 50%;
  }
  .Footer .link-list a .link{
    height: 100%;
    padding-bottom: 5px;
  }
  .Footer .sub-foot .top{
    padding-top: 90px;
  }
  .Footer .sub-foot .bottom{
    padding:70px 4% 20px;
  }
  .Footer .sub-foot .bottom .button{
    padding: 12px 6% 10px;
  }
  .footnote-wrapper{
    max-width: 100%;
    margin-bottom: 50px;
  }
  .intro_crop_images img{
    max-width: 50%;
  }
  .crop-image-wrapper{
    flex-wrap: wrap;
  }
  .crop-image-wrapper-2 .crop-image{
  flex: 0 0 calc(100%);
  }
  .crop-image-wrapper-2 .crop-image:nth-of-type(1){
    margin-bottom: 23px;
  }
  .crop-image-wrapper-3 .crop-image{ 
    flex: 0 0 calc(100%);
  }
  .crop-image-wrapper-4 .crop-image{
    flex: 0 0 calc(100%);
  }
  .crop-image-wrapper-5 .crop-image{
    flex: 0 0 calc(100%);
  }
  .text-w-footnote .text-wrapper{
    margin-left:0;
  }
  .text-w-footnote{
    flex-direction: column;
    margin-bottom: 12px;
  }

}


